/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMedicalCenter = /* GraphQL */ `
  query GetMedicalCenter($id: ID!) {
    getMedicalCenter(id: $id) {
      id
      MedicalCenterName
      MedicalCenterCountry
      MedicalCenterCity
      createdAt
      updatedAt
    }
  }
`;
export const listMedicalCenters = /* GraphQL */ `
  query ListMedicalCenters(
    $filter: ModelMedicalCenterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedicalCenters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        MedicalCenterName
        MedicalCenterCountry
        MedicalCenterCity
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSurgery = /* GraphQL */ `
  query GetSurgery($id: ID!) {
    getSurgery(id: $id) {
      id
      MedicalCenterID
      SurgeryID
      PatientID
      PatientName
      Date
      RelativePhone
      RelativeEmail
      RelativeName
      PreSurgicalInitialTimestamp
      PreSurgicalCompleteInitialTimestamp
      SurgeryInitialTimestamp
      SurgeryCompleteInitialTimestamp
      PostSurgicalInitialTimestamp
      PostSurgicalCompleteInitialTimestamp
      Complicated
      createdAt
      updatedAt
    }
  }
`;
export const listSurgeries = /* GraphQL */ `
  query ListSurgeries(
    $filter: ModelSurgeryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurgeries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        MedicalCenterID
        SurgeryID
        PatientID
        PatientName
        Date
        RelativePhone
        RelativeEmail
        RelativeName
        PreSurgicalInitialTimestamp
        PreSurgicalCompleteInitialTimestamp
        SurgeryInitialTimestamp
        SurgeryCompleteInitialTimestamp
        PostSurgicalInitialTimestamp
        PostSurgicalCompleteInitialTimestamp
        Complicated
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
