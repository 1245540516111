/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMedicalCenter = /* GraphQL */ `
  subscription OnCreateMedicalCenter {
    onCreateMedicalCenter {
      id
      MedicalCenterName
      MedicalCenterCountry
      MedicalCenterCity
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMedicalCenter = /* GraphQL */ `
  subscription OnUpdateMedicalCenter {
    onUpdateMedicalCenter {
      id
      MedicalCenterName
      MedicalCenterCountry
      MedicalCenterCity
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMedicalCenter = /* GraphQL */ `
  subscription OnDeleteMedicalCenter {
    onDeleteMedicalCenter {
      id
      MedicalCenterName
      MedicalCenterCountry
      MedicalCenterCity
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSurgery = /* GraphQL */ `
  subscription OnCreateSurgery {
    onCreateSurgery {
      id
      MedicalCenterID
      SurgeryID
      PatientID
      PatientName
      Date
      RelativePhone
      RelativeEmail
      RelativeName
      PreSurgicalInitialTimestamp
      PreSurgicalCompleteInitialTimestamp
      SurgeryInitialTimestamp
      SurgeryCompleteInitialTimestamp
      PostSurgicalInitialTimestamp
      PostSurgicalCompleteInitialTimestamp
      Complicated
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSurgery = /* GraphQL */ `
  subscription OnUpdateSurgery {
    onUpdateSurgery {
      id
      MedicalCenterID
      SurgeryID
      PatientID
      PatientName
      Date
      RelativePhone
      RelativeEmail
      RelativeName
      PreSurgicalInitialTimestamp
      PreSurgicalCompleteInitialTimestamp
      SurgeryInitialTimestamp
      SurgeryCompleteInitialTimestamp
      PostSurgicalInitialTimestamp
      PostSurgicalCompleteInitialTimestamp
      Complicated
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSurgery = /* GraphQL */ `
  subscription OnDeleteSurgery {
    onDeleteSurgery {
      id
      MedicalCenterID
      SurgeryID
      PatientID
      PatientName
      Date
      RelativePhone
      RelativeEmail
      RelativeName
      PreSurgicalInitialTimestamp
      PreSurgicalCompleteInitialTimestamp
      SurgeryInitialTimestamp
      SurgeryCompleteInitialTimestamp
      PostSurgicalInitialTimestamp
      PostSurgicalCompleteInitialTimestamp
      Complicated
      createdAt
      updatedAt
    }
  }
`;
